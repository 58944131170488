<template>
  <div class="app-container">
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            :src="scope.row.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.nickname ? scope.row.nickname : "" }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row ? scope.row.phone : "" }}
        </template>
      </el-table-column>

      <el-table-column label="留言" min-width="95" align="center">
        <template slot-scope="scope">
          {{
            scope.row.say_log.length > 0 ? scope.row.say_log[0].msg_body : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="未读留言" min-width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.no_read_num }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="是否已处理" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleUpdate(scope.row)"
          >
            查看详情
          </el-button>
          <!-- <el-button
            size="mini"
            type="warning"
            @click="handleDel(scope.row.id)"
          >
            处理
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import Tinymce from "@/components/Tinymce";
import UploadList from "@/components/UploadList/index.vue";

export default {
  components: {
    Tinymce,
    UploadList,
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        has_say: 1,
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        name: "",
        summary: "",
        cover: null,
        detail: "",
        images: [],
      },
      rules: {
        cover: [
          { required: true, message: "项目封面不能为空", trigger: "change" },
        ],
        images: [
          {
            type: "array",
            required: true,
            message: "轮播图不能为空",
            trigger: "change",
          },
        ],
        name: [
          { required: true, message: "项目名称不能为空", trigger: "change" },
        ],
        summary: [
          { required: true, message: "项目概述不能为空", trigger: "change" },
        ],
        detail: [
          { required: true, message: "项目详情不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/user/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        summary: "",
        cover: null,
        detail: "",
        images: [],
      };
      this.btnLoading = false;
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.$router.push(`/website/LookChat?id=${row.id}`);
      return;
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/project/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleDel(val) {
      this.$confirm("确定要删除, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/project/del",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
